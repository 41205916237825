import {ethers} from 'ethers';
import Config from '../../Config.json'

import {abi} from "../abi/contractAbi";
import {claimRewardAbi} from "../abi/claimRewardAbi";

import axios from 'axios';


export default class Core {
    constructor(vueContext){
        this.context = vueContext
        this.farmAddress = Config.FARM_ADDRESS
        this.farmAdminAddress = Config.FARM_ADMIN_ADDRESS
        this.claimAddress = Config.CLAIM_ADDRESS
        this.defaultReferrer = Config.DEFAULT_REFERRER
        this.init()
    }

    async init() {
        if(!this.contract){
            if(window.localStorage.getItem("selectedWallet") === "metamask" && window.ethereum){

                if(window.ethereum.chainId === "0x1" || window.ethereum.chainId == 1) {
                    let provider =  new ethers.providers.Web3Provider(window.ethereum);
                    this.providerAddress = provider;
                    this.signer = provider.getSigner();
                    this.farm = new ethers.Contract(this.farmAddress,  abi, provider).connect(this.signer);
                    this.claim = new ethers.Contract(this.claimAddress,  claimRewardAbi, provider).connect(this.signer);

                    return this.farm;
                }else {
                    throw new Error("Please change the network in Metamask to mainnet")
                }
                

            //TODO return when deployed to binance 
            // }else if (window.localStorage.getItem("selectedWallet") === "binance" && window.BinanceChain) {
            //     if(window.BinanceChain.chainId === "0x38" || window.BinanceChain.chainId == 56) {
            //         let provider =  new ethers.providers.Web3Provider(window.ethereum);
            //         this.providerAddress = provider;
            //         this.signer = provider.getSigner();
            //         this.farm = new ethers.Contract(this.farmAddress,  abi, provider).connect(this.signer);
            //         this.farmAdmin = new ethers.Contract(this.farmAdminAddress,  abi, provider).connect(this.signer);

            //         return this.farm;
            //     }else {
            //         throw new Error("Please change the network in Binance Chain Wallet to mainnet")
            //     }
                
            }else {
                let provider = ethers.getDefaultProvider('mainnet');
                this.providerAddress = provider;
                // this.signer = provider.getSigner();
                this.farm = new ethers.Contract(this.farmAddress,  abi, provider);

                return this.farm;
            }
            
        }

        return this.farm;
    }

    getSiteData(period=3600000){
        
        let _this = this;

        setTimeout(async function tick() {

            try {
                let totalTokensNumber = await _this.totalTokensCount()
                // let farmTokens = await _this.getFarmingTokens()
                let stakeTokens = Config.stakeTokens;


                _this.context.$store.commit('setTotalTokensNumber', totalTokensNumber);
                _this.context.$store.commit('setStakeTokens', stakeTokens);


            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);
            setTimeout(tick, 300);
            }
        }, 300);

        
    }

    getOptimalGasPrice(period = 60000 /* miliseconds */) {
        let _this = this;
        setTimeout(async function tick() {
          let currentGasPrice;
          const now = new Date;
          const requestTimestampUtc = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
                now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
          try {
            if(localStorage.getItem('gasPrice') && localStorage.getItem('gasPriceTimeStamp')){
              if(localStorage.getItem('gasPriceTimeStamp') && (requestTimestampUtc <= (+localStorage.getItem('gasPriceTimeStamp') + 600000))){
                _this.context.$store.commit('setGasPrice', localStorage.getItem('gasPrice'));
                _this.gasPrice = localStorage.getItem('gasPrice')
              }else if(localStorage.getItem('gasPriceTimeStamp') && (requestTimestampUtc > (+localStorage.getItem('gasPriceTimeStamp') + 600000))){
                currentGasPrice = await _this.fetchGasPrice();

                localStorage.setItem('gasPrice', currentGasPrice);
                localStorage.setItem('gasPriceTimeStamp', requestTimestampUtc);
                _this.context.$store.commit('setGasPrice', currentGasPrice);
                _this.gasPrice = currentGasPrice.toString()
              }
            }else {
                currentGasPrice = await _this.fetchGasPrice();
                localStorage.setItem('gasPrice', currentGasPrice);
                localStorage.setItem('gasPriceTimeStamp', requestTimestampUtc);
                _this.context.$store.commit('setGasPrice', currentGasPrice);
                _this.gasPrice = currentGasPrice.toString()
              }
            setTimeout(tick, period);
          } catch (ex) {
              console.log(ex);
            setTimeout(tick, period);
          }
        }, 300);
    }

    async fetchGasPrice() {
        // const res = await axios.get('https://ethgasstation.info/api/ethgasAPI.json?'); //gasStationAPI - gas price is too high
        const res = await axios.get('https://api.metaswap.codefi.network/gasPrices');
        return Math.round(Number(res.data.FastGasPrice) * Config.GAS_PRICE_COEF);
    }

    updateUserInfo(userAddress, period=10000){
        let _this = this;

        setTimeout(async function tick() {
            try {
                let stakeTokens = _this.context.$store.getters.getStakeTokens
                const poolStartTime = Config.POOL_START_TIME
                
                let poolEndTime = poolStartTime + (86400 * Config.STAKE_DAYS) //seconds in 24 hours * 90 days
                let currentTime = Math.floor(new Date().getTime() / 1000);

                let claimReceived = await _this.checkIfClaimReceived(userAddress);
                _this.context.$store.commit('setClaimStatus', claimReceived)

                //TODO add alternative check
                // if(!farmTokens.length){
                //     throw new Error
                // }
                  
                  let stakingDataRaw =  await _this.getStakingDetails(userAddress);

                  for (let i=0; i < stakingDataRaw.length; i++){
                    if(!stakingDataRaw[i].isActive){

                      let stakeAmount = await _this.getStakeReward(userAddress, stakingDataRaw[i].stakeId);
                      stakingDataRaw[i].stakeAmount = stakeAmount
            

                    }
                  }
                  let stakingData = stakingDataRaw.map(el => {
  
                    let stakeTokenAddress = el.tokenAddress;
                    let farmTokensList = stakeTokens.find(stakeToken => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase()).farmTokensList;
                    
                    let stakeTokenData = Config.stakeTokens.find(stakeToken => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase());
                    
                    return {
                      ...el,
                      name: stakeTokenData.name,
                      maxStake: stakeTokenData.totalMaxStake,
                      userMaxStake: stakeTokenData.userMaxStake,
                      decimals: stakeTokenData.decimals,
                      farmTokensList,
                    }
                  })

  
                  for (let i=0; i<stakingData.length; i++) {
                    if(stakingData[i].isActive == true){
                      let tokenAddress = stakingData[i].tokenAddress;
                      let stakeAmount = stakingData[i].stakeAmount;
                      let stakeDate = stakingData[i].startTime;


                      let farmTokensReward = [];
                      let rewardCoefs = []
                      for(let j = 0; j< stakingData[i].farmTokensList.length; j++){
                       const interval = stakingData[i].farmTokensList[j].interval * 86400;
                       if(poolEndTime > currentTime){
                           poolEndTime = currentTime
                       }
                       
                       let possibleRewardEarned;
                       
                       let rewardCoef;


                       if(interval === 86400 && currentTime >= (stakeDate + 3600)){ //rewards are accesseble after 1hour
                            const rewardForOneDay =  await _this.getRewardForPeriod(stakeAmount, tokenAddress, stakingData[i].farmTokensList[j].address)
                            possibleRewardEarned  = Math.floor((poolEndTime - stakeDate) / 3600) * rewardForOneDay;
                            rewardCoef = rewardForOneDay;
                            
                            let refReward = possibleRewardEarned * 5 / 100;

                            if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                                possibleRewardEarned -= refReward;
                            }else {
                                possibleRewardEarned -= (refReward*2);
                            }
                       }else if (interval > 86400 && currentTime >= (stakeDate + interval + 3600)){
                            const rewardForOneDay =  await _this.getRewardForPeriod(stakeAmount, tokenAddress, stakingData[i].farmTokensList[j].address)

                            let noOfDaysInHours = Math.floor((poolEndTime - stakeDate) / 3600);
                            possibleRewardEarned  = Math.floor(noOfDaysInHours - ((interval - 86400) / 3600)) * rewardForOneDay;
                            rewardCoef = rewardForOneDay;

                            let refReward = possibleRewardEarned * 5 / 100;

                            if(stakingData[i].referrer !== "0x0000000000000000000000000000000000000000") {
                                possibleRewardEarned -= refReward;
                            }else {
                                possibleRewardEarned -= (refReward*2);
                            }
                       }else {
                            possibleRewardEarned = 0
                       }
                       

                      farmTokensReward.push(possibleRewardEarned);
                      rewardCoefs.push(rewardCoef)
                      }
                      stakingData[i]['farmTokensReward'] =  farmTokensReward 
                      stakingData[i]['rewardCoefs'] = rewardCoefs
                    }
                    continue
                  }

                  const refNumber = await _this.getReferrals(userAddress);
                  _this.context.$store.commit('setReferralsNumber', refNumber)
                  _this.context.$store.commit('setStakesInfo', stakingData)
                  


            setTimeout(tick, period);
            } catch (ex) {
            console.log(ex);
            setTimeout(tick, 300);
            }
        }, 300);
        

    }

    // async getFarmingTokens() {
    //     let stakeTokensAddresses = Config.stakeTokens;
    //     let farmTokensAddresses = []

    //     // for (let i = 0; i < stakeTokensAddresses.length; i++){
    //     //     let address = await this.farm.getPoolTokens(stakeTokensAddresses[i].address);
    //     //     farmTokensAddresses.push(address)
    //     // }

    //     let allTokens = (stakeTokensAddresses.map((token, index) => {
    //         let farmTokensData = farmTokensAddresses[index].map(el => {
    //             let farmTokenData = Config.stakeTokens.find(farmToken =>  farmToken.address === el).farmTokens;
    //             return farmTokenData
    //             })

    //         return {
    //             tokenName: token.name,
    //             tag: token.tag,
    //             tokenAddress: token.address,
    //             tokenDecimals: token.decimal,
    //             userMinStake: token.userMinStake,
    //             userMaxStake: token.userMaxStake,
    //             totalMaxStake: token.totalMaxStake,
    //             // lockableDays: token.lockableDays,
    //             // optionableStatus: token.optionableStatus,
    //             farmTokensData: farmTokensData,
    //         }

    //     }))

    //     return allTokens
    // }

    async getStakingDetails(userAddress) {
        if(!this.farm) {
            await this.init()
        }
        let resultRaw = await this.farm.viewStakingDetails(userAddress);
        let result = []


    
        function getCol(array, col){
            var column = [];
            for(var i=0; i<array.length; i++){
               column.push(array[i][col]);
            }
            return column; 
         }
             
        for(let i = 0; i < resultRaw[0].length; i++){
            
            let column = getCol(resultRaw, i)


            let stakeObj = {
                referrer: column[0],
                tokenAddress: column[1],
                isActive: column[2],
                stakeId: Number.parseInt(column[3]),
                stakeAmount: Number(column[4]) / 10 ** 18,
                startTime: Number.parseInt(column[5]),
            }
            result.push(stakeObj);
        }
        result.sort((a, b) => b.startTime - a.startTime)

        return result;
        
    }

    async getUserTotalStaking(userAddress, tokenAddress) {
        let resultRaw = await this.farm.userTotalStaking(userAddress, tokenAddress);

    }

    async stake(tokenAddress, amount, decimals, ref="") {
        if(!ref) {
            ref = this.defaultReferrer;
        }

        amount =  ethers.utils.parseUnits(`${amount}`, decimals)
        let rawTransactions = await this.farm.stake(ref, tokenAddress, amount, {gasPrice: ethers.utils.parseUnits((this.gasPrice || Config.DEFAULT_GAS_PRICE_GWEI), "gwei")})
        return rawTransactions;
    }

    async unstake(userAddress, stakeId){
        // let rawTransactions = await this.farm.unstake(userAddress, stakeId, {gasPrice: ethers.utils.parseUnits((this.gasPrice || Config.DEFAULT_GAS_PRICE_GWEI), "gwei")})
        let rawTransactions = await this.claim.claim()
        return rawTransactions;
    }

    async checkIfClaimReceived(userAddress){
        let result = await this.claim.receivedClaims(userAddress);
        return result
    }

    async approve(tokenContract, amount, decimals){


        amount = ethers.utils.parseUnits(`${amount}`, decimals)

        const rawTransaction = await tokenContract.approve(this.farmAddress, amount, {gasPrice: ethers.utils.parseUnits((this.gasPrice || Config.DEFAULT_GAS_PRICE_GWEI), "gwei")})
        return rawTransaction;
    }

    async totalTokensCount(){
        const result = await this.farm.totalTokensCount();
        return result;
    }

    async getPoolStartTime() {
        const resultRaw = await this.farm.poolStartTime();
        return Number.parseInt(resultRaw._hex)
    }

    async getTotalStaking(poolAddress) {
        const resultRaw = await this.farm.totalStaking(poolAddress);
        const result = Number(ethers.utils.formatUnits(resultRaw._hex));
        return result;
    }

    async checkReferrer(refAddress) {
        const result = await this.farm.isActiveUser(refAddress);

        return result;
    }

    async getReferrals(userAddress) {
        const result = await this.farm.users(userAddress);
        return Number.parseInt(result._hex);
    }

    async getUserReward(userAddress, period=60000) { /* miliseconds */
            let _this = this;

            setTimeout(async function tick() {
            try {
                let blockchainLastBlock = await _this.providerAddress.getBlockNumber()
                let startBlock = Config.START_BLOCK;
                let lastBlock
                //берем ивенты
                let events = JSON.parse(localStorage.getItem(`${userAddress}`));
                //если пусто
                if(!events) {
                    lastBlock = Number(startBlock);
                }else if (events && events.from){
                    //если есть ивенты, сканируем от последнего записанного блока по +=5000
                    startBlock = Number(events.from) + 1
                }

              //устанавливаем по какой блок сканировать ивенты
                for (let from = startBlock; from <= blockchainLastBlock;) {

                if(from > blockchainLastBlock){
                    from = blockchainLastBlock;
                }
                let to = from + 5000


                // let filterClaim = _this.farm.filters.Claim(userAddress);
                // filterClaim.fromBlock = from;
                // filterClaim.toBlock = to;
                // let eventsRegister = await _this.providerAddress.getLogs(filterClaim)

                let resultClaimRaw = await _this.farm.queryFilter("Claim", from, to)
                // let resultUnstakeRaw = await _this.farm.queryFilter("UnStake", from, to)
                // console.log(resultUnstakeRaw)
                let resultRefRaw = await _this.farm.queryFilter('ReferralEarn', from, to)


                let claimEvents = resultClaimRaw.map(el => {
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        stakedTokenAddress: el.args[1],
                        tokenAddress: el.args[2],
                        amount: Number(ethers.utils.formatUnits(el.args[3])),
                        stakeId: Number.parseInt(el.args[4]),
                        endTime: Number.parseInt(el.args[5]),
                        transactionHash: el.transactionHash
                    }
                })
 
                let refRewardsEvents = resultRefRaw.map(el => {
                    return {
                        eventName: el.event,
                        userAddress: el.args[0],
                        callerAddress: el.args[1],
                        rewardTokenAddress: el.args[2],
                        rewardAmount: Number(ethers.utils.formatUnits(el.args[3])),
                        endTime: Number.parseInt(el.args[4]),
                        transactionHash: el.transactionHash
                    }
                })

                let userClaimEvents = claimEvents.filter(event => event.userAddress.toLowerCase() == userAddress.toLowerCase());
                let userRefRewardsEvents = refRewardsEvents.filter(event => event.userAddress.toLowerCase() == userAddress.toLowerCase());

                let userEvents = JSON.parse(localStorage.getItem(`${userAddress}`)) || [];

                if (userEvents.length === 0){

                    userEvents = {
                        from,
                        claimEvents: [...userClaimEvents],
                        refReward: [...userRefRewardsEvents]
                    };
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }else{

                    let previousClaimEvents = userEvents.claimEvents || [];
                    let previousRefReward = userEvents.refReward || [];

                    let refReward = [...previousRefReward, ...userRefRewardsEvents];
                    refReward = refReward.reduce(
                      (x, y) => x.findIndex(e => (e.transactionHash == y.transactionHash && e.rewardTokenAddress == y.rewardTokenAddress)) < 0 ? [...x, y]: x, []
                    )

                    userEvents = {
                        from,
                        claimEvents: [...previousClaimEvents, ...userClaimEvents],
                        refReward
                    }
                    localStorage.setItem(`${userAddress}`, JSON.stringify(userEvents))
                }
                if(from == blockchainLastBlock) {

                    setTimeout(tick, period)
                    return
                }else if (from + 5000 > blockchainLastBlock) {

                    
                    from = blockchainLastBlock

                }else {

                    from+= 5000

                }
            

                }
                setTimeout(tick,period)
            } catch (error) {
            //   console.log(error);
              setTimeout(tick, 300)
            }
        }, 300)
        
    }

    async getStakeReward(userAddress, stakeId) {
        
        let resultRaw = await this.farm.getStakeAmount(userAddress, stakeId);
        
        let result = Number(ethers.utils.formatUnits(resultRaw));
        return result;
    }

    async getRewardForPeriod(stakedAmount, stakedToken, rewardToken){
        try {
            const totalStaking = await this.getTotalStaking(stakedToken) ;

                const totalStakingBig = ethers.utils.parseEther(`${totalStaking}`, "ether")
                const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")

                const rewardRaw = await this.farm.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                const reward = (ethers.utils.formatUnits(rewardRaw));
                return reward;


        } catch (error) {
            console.log(error);
        }
        
    }


    async getPossibleDailyReward(stakedAmount, stakedToken, rewardToken) {
        try {
            const totalStaking = await this.getTotalStaking(stakedToken);
            if(totalStaking > 0) {
                const totalStakingBig = ethers.utils.parseEther(`${totalStaking}`, "ether")
                const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")
                const newTotalStaking = Number(stakedAmount) + totalStaking;
                const newTotalStakingBig = ethers.utils.parseEther(`${newTotalStaking}`, "ether");

                const rewardRaw = await this.farm.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, newTotalStakingBig);
                const reward = (ethers.utils.formatUnits(rewardRaw));
                return reward;
            }else {
            //если это первый стейк на пуле
            const stakedAmountBig = ethers.utils.parseEther(`${stakedAmount}`, "ether")
            const rewardRaw = await this.farm.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, stakedAmountBig);
            const reward = (ethers.utils.formatUnits(rewardRaw))
            return reward
            }   
        } catch (error) {
            // console.log(error);
        }
    }

    withoutRound(number, roundTo=2){
        if(roundTo === 2){
            if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,2})?/)[0]
             }
        }else if(roundTo === 4){
             if(number.toString().indexOf(".") !== -1){

                 const splittedNumber = number.toString().split(".")
                 splittedNumber[1]+="00";
                 number = splittedNumber.join(".");

             return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }else {
                 number = number.toString()+".00";
                 return (number.toString()).match(/^-?\d+(?:\.\d{0,4})?/)[0]
             }
    }
    }

    
}