<template>
  <div v-if="noWallet" class="modal">
      <div class="modal-wrapper">
          <div class="modal-header">
              <div class="title">{{lang.get("WALLET_NEEDED")}}</div>
              <button @click="$emit('close')" data-cv="btn-close-switch-wallet"> <i class="i-close"></i></button>
          </div>
          <div class="modal-content">
              <ul class="list-select list-select_wallet"  data-cy="list-select-wallet">
                <li>                    
                  <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">
                      <div class="wallet_img">
                        <div class="wallet-metamask"></div>
                      </div>
                      <span>Metamask</span>
                  </a>
                </li>
                <li>                    
                  <a href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&referrer=utm_source%3Dwebsite" target="_blank">
                      <div class="wallet_img">
                        <div class="wallet-trust"></div>
                      </div>
                      <span>Trust Wallet</span>
                  </a>
                </li> 
                    <!-- <li >
                        <div class="token_img">
                          <div class="token-a"></div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">Metamask</a>
                      </li> 
                      <li >
                        <div class="token_img">
                          <div class="token-a"></div>
                        </div>
                        <a href="https://trustwallet.com/" target="_blank">Trust Wallet</a>
                      </li>  -->
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
  name: "ConnectWalletModal",
  props: ['noWallet'],
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  mounted() {
  this.lang.init()
}
}
</script>